import Constants from 'Constants'
import Common from '@/assets/js/common.js'
import TriggerTransformer from '@/assets/js/dexie/transformers/TriggerTransformer.js'
import TriggeredTransformer from '@/assets/js/dexie/transformers/TriggeredTransformer.js'
import QuestionTransformer from '@/assets/js/dexie/transformers/QuestionTransformer.js'

var TriggerMixin = {
	mixins: [],
	data() {
		return {

		}
	},
	methods: {
		getTriggerEntity: async function(triggerentity_id) {
			return this.$storage.db.t('trigger_entity')
                .then(table => {
                    return table.where({triggerentity_id: parseInt(triggerentity_id)})
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('heavy'))
                }).then(triggerentity => {
                    return triggerentity[0]
                })
		},
		loadTriggerEntity: async function() {
			return this.$storage.db.t('trigger_entity')
                .then(table => {
                    return table.toCollection()
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('heavy'))
                })
		},
		loadTriggerEntityType: async function() {
			return this.$storage.db.t('trigger_entity_type')
                .then(table => {
                    return table.toCollection()
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('entityType'))
                })
		},
		loadTriggeredEntityType: async function(gamme_filter=false) {
			return this.$storage.db.t('triggered_entity_type')
                .then(table => {
                    return table.toCollection()
                })
                .then(col => {
                    return col.transform(new TriggeredTransformer('triggeredEntityType'))
                })
				.then(res => {
					// Si pas de filtre par gamme
					if(!gamme_filter) return res
					// Si accès à la facturation, on ne filtre pas
					if(this.$store.state.userAccess.hasFacturationAccess) return res

					return res.filter(r => r.triggeredentitytype_type != 'horse_pension')
				})
		},
		loadQuestionFromType: async function(actetype_id) {
			return this.$storage.db.t('question')
			.then(table => {
				return table.where({
					question_fk: parseInt(actetype_id),
					question_type: 'horse_actes_type'
				})
			}).then(col => {
				return col.transform(new QuestionTransformer())
			})
		},
		loadTriggers: async function(triggerentity_id) {
			return this.$storage.db.t('trigger')
                .then(table => {
                    return table.where({trigger_triggerentity: parseInt(triggerentity_id)})
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('trigger'))
                })
		},
		getTriggerByActe: async function(acte_type, questions) {
			let triggerentity = await this.$storage.db.t('trigger_entity')
                .then(table => {
                	return table.toCollection()
                	.filter(t => t.triggerentity_actestype == parseInt(acte_type) || (t.triggerentity_actestype == null && t.triggerentity_triggerentitytype == 1))
                    // return table.where({triggerentity_actestype: parseInt(acte_type)})
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('entityWithPeriod'))
				})
				.then(res => {
					return res.filter(line => line.active === true)
				})

            let res = []
            await Promise.all(triggerentity.map(async (entity) => {
            	let trigger = await this.$storage.db.t('trigger')
	                .then(table => {
	                    return table.where({trigger_triggerentity: parseInt(entity.triggerentity_id)})
	                })
	                .then(col => {
	                    return col.transform(new TriggerTransformer('trigger'))
	                })
	            let keep = entity.active
	            let nb_question = 0 
	            let nb_valide = 0
	            trigger.forEach(tri => {
	            	nb_question++;
	            	if(Object.prototype.hasOwnProperty.call(questions, tri.question.question_id)) {
	            		tri.trigger_reponse.forEach(reponse => {
	            			let question = questions[tri.question.question_id]
	            			if(question.every(q => q == undefined)) {
	            				question = []
	            			}
	            			else {
		            			question = question.map(q => q.id)
		            		}
	            			if(question.includes(reponse.name) || reponse.id == "-1" || reponse.id === -1) {
	            				nb_valide++;
	            			}
	            		})
	            	} else {
	            		tri.trigger_reponse.forEach(reponse => {
	            			if(reponse.id == "-1" || reponse.id === -1) {
	            				nb_valide++;
	            			}
	            		})
	            	}
	            	keep = nb_question === nb_valide ? keep : false;
	            })
            	if(keep) {
            		if(entity.entity_type == "acte" && !entity.actestype_label) {
            			entity.actestype_label = this.getTrad('trigger.add_new_act')
            		}

            		res.push(entity)
            	}
            }))
            // on tri par ordre alphabetique des actes déclenchés
            res.sort((a, b) => {
            	const label_a = a.entity_type === 'acte' ? a.actestype_label : a.entity_type
            	const label_b = b.entity_type === 'acte' ? b.actestype_label : b.entity_type

				if (label_a < label_b){
					return -1;
				}
				if (label_a > label_b){
					return 1;
				}
				return 0;
			})
            return res
		},
		getTriggerByMouvement: async function(type_mvt, date_retour, mare = false, season = false, from = '') {
			// mouvement_sortie par défaut
			let entity_label = "mouvement_sortie"

			if(type_mvt == 'interne') {
				// entity_label =  "mouvement_interne"
				return false // pas de trigger pour les mouvements internes
			}
			else if(type_mvt == 'entree') {
				entity_label =  "mouvement_entree"
			}
			
			const type = await this.$storage.db.t('trigger_entity_type')
                .then(table => {
                    return table.where({triggerentitytype_label: entity_label})
                })
                .then((col) => {
					return col.first()
				})

			let triggerentity = await this.$storage.db.t('trigger_entity')
                .then(table => {
                    return table.where({triggerentity_triggerentitytype: parseInt(type.triggerentitytype_id)})
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('entityWithPeriod'))
				})
				.then(res => {
					return res.filter(line => line.active === true)
				})
				.then(res => {
					// Si accès à la facturation, on ne filtre pas
					if(this.$store.state.userAccess.hasFacturationAccess) return res
					return res.filter(r => r.triggeredentitytype_type != 'horse_pension')
				})

			let res = []
            await Promise.all(triggerentity.map(async (entity) => {
            	let keep = true

        		if(entity.entity_type == "pension_reouverture" && !date_retour) {
        			keep = false
        		}
        		
        		if(entity.entity_type == "interroger_dps" && (!mare || !season || !['horseAjout', 'SearchSire'].includes(from))) {
        			keep = false
        		}

            	if(keep) {
            		if(entity.entity_type == "acte" && !entity.actestype_label) {
            			entity.actestype_label = this.getTrad('trigger.add_new_act')
            		}

            		res.push(entity)
            	}
            }))

            // on tri par ordre alphabetique des actes déclenchés
            res.sort((a, b) => {
            	const label_a = a.entity_type === 'acte' ? a.actestype_label : a.entity_type
            	const label_b = b.entity_type === 'acte' ? b.actestype_label : b.entity_type

				if (label_a < label_b){
					return -1;
				}
				if (label_a > label_b){
					return 1;
				}
				return 0;
			})
            return res
		},
		getTriggerByPoulain: async function() {
			const type = await this.$storage.db.t('trigger_entity_type')
                .then(table => {
                    return table.where({triggerentitytype_label: 'poulain'})
                })
                .then((col) => {
					return col.first()
				})

			let triggerentity = await this.$storage.db.t('trigger_entity')
                .then(table => {
                    return table.where({triggerentity_triggerentitytype: parseInt(type.triggerentitytype_id)})
                })
                .then(col => {
                    return col.transform(new TriggerTransformer('entityWithPeriod'))
				})
				.then(res => {
					return res.filter(line => line.active === true)
				})

            // on tri par ordre alphabetique des actes déclenchés
            triggerentity.sort((a, b) => {
            	const label_a = a.entity_type === 'acte' ? a.actestype_label : a.entity_type
            	const label_b = b.entity_type === 'acte' ? b.actestype_label : b.entity_type

				if (label_a < label_b){
					return -1;
				}
				if (label_a > label_b){
					return 1;
				}
				return 0;
			})
            return triggerentity
		},
		createTrigger: async function(params) {
			let triggerentity_id = Common.getNegativeId()
			let insert = {
	            triggerentity_id 				: triggerentity_id,
	            triggerentity_actestype 		: params.triggerentity_actestype === 0 ? null : parseInt(params.triggerentity_actestype),
				triggerentity_triggerentitytype : params.triggerentity_triggerentitytype,
				triggerentity_valide 			: 1,
				triggerentity_actif 			: true
	        }
		    if(!params.global)
		    	insert.triggerentity_licencekey = Constants.USER_LICENCE_KEY

		    return this.$storage.db.transaction(
                'rw',
                ['trigger_entity', 'trigger', 'triggered', 'triggered_period'],
                async () => {
				await this.$storage.db.t('trigger_entity')
			    .then(table => {
			        return table.add(insert)
			    })

			    await params.questions.forEach(async (question, index) => {
			    	let insert = {
			    		trigger_id 				: Common.getNegativeId(),
						trigger_question 		: question.id,
						trigger_reponse 		: params.responses[index],
						trigger_valide 			: 1,
						trigger_triggerentity 	: parseInt(triggerentity_id)
					}
					if(!params.global)
			    		insert.trigger_licencekey = Constants.USER_LICENCE_KEY
			    	await this.$storage.db.t('trigger')
				    .then(table => {
				        return table.add(insert)
				    })
			    })

			    let triggered_id = Common.getNegativeId()
			    insert = {
			    	triggered_id 					: triggered_id,
					triggered_triggerentity 		: parseInt(triggerentity_id),
					triggered_actestype 			: params.triggered_actestype === 0 ? null : parseInt(params.triggered_actestype),
					triggered_categorie 			: params.triggered_categorie === 0 ? null : parseInt(params.triggered_categorie),
					triggered_valide 				: 1,
					triggered_triggeredentitytype 	: params.triggeredentity_triggeredentitytype
				}
	 			if(!params.global)
			    	insert.triggered_licencekey = Constants.USER_LICENCE_KEY
			    await this.$storage.db.t('triggered')
			    .then(table => {
			        return table.add(insert)
			    })

			    await params.periods.periods.forEach(async (period, index) => {
			    	let insert = {
			    		triggeredperiod_id 			: Common.getNegativeId(),
			    		triggeredperiod_triggered 	: parseInt(triggered_id),
			    		triggeredperiod_label 		: params.periods.label[index],
						triggeredperiod_label_en 	: params.periods.label[index],
						triggeredperiod_begin_at 	: params.periods.periods[index],
						triggeredperiod_recurrence 	: params.periods.pas[index],
						triggeredperiod_duration 	: params.periods.duree[index],
						triggeredperiod_billable 	: params.periods.billable[index].id,
						triggeredperiod_actestype	: params.periods.actestype[index] ? params.periods.actestype[index].id : null,
						triggeredperiod_valide 		: 1,
			    	}
			    	if(!params.global)
			    		insert.triggeredperiod_licencekey = Constants.USER_LICENCE_KEY
				    await this.$storage.db.t('triggered_period')
				    .then(table => {
				        return table.add(insert)
				    })
			    })

			    return true;
			})
		},
		updateTrigger: async function(params, triggerentity_id) {
			let update = {
	            triggerentity_actestype 		: parseInt(params.triggerentity_actestype) || null,
				triggerentity_triggerentitytype : params.triggerentity_triggerentitytype,
				triggerentity_valide 			: 1,
				triggerentity_actif 			: true,
				triggerentity_licencekey 		: ""
	        }
		    if(!params.global)
		    	update.triggerentity_licencekey = Constants.USER_LICENCE_KEY

		    return this.$storage.db.transaction(
                'rw',
                ['trigger_entity', 'trigger', 'triggered', 'triggered_period', 'trigger_entity_type'],
                async () => {
					this.$storage.db.t('trigger_entity')
				    .then(table => {
				        return table.update(parseInt(triggerentity_id), update)
				    })

				    this.$storage.db.t('trigger')
					.then(table => {
						return table.where('trigger_triggerentity').equals(parseInt(triggerentity_id)).invalid()
					})

				    params.questions.forEach(async (question, index) => {
				    	let insert = {
				    		trigger_id 				: Common.getNegativeId(),
							trigger_question 		: question.id,
							trigger_reponse 		: params.responses[index],
							trigger_valide 			: 1,
							trigger_triggerentity 	: parseInt(triggerentity_id),
							trigger_licencekey 		: ""
						}
						if(!params.global)
				    		insert.trigger_licencekey = Constants.USER_LICENCE_KEY
				    	this.$storage.db.t('trigger')
					    .then(table => {
					        return table.add(insert)
					    })
				    })

				    // récupération du trigger_id pour supprimer les trigger_period correspondantes
				    let triggered_id = await this.$storage.db.t('triggered')
				        .then(table => {
				            return table.where({triggered_triggerentity: parseInt(triggerentity_id)})
				        })
				        .then(col => {
				            return col.transform(new TriggeredTransformer('veryLight'))
				        }).then(triggered => {
				            return triggered[0].triggered_id
				        })
				    this.$storage.db.t('triggered_period')
					    .then(table => {
					    	return table.where('triggeredperiod_triggered').equals(parseInt(triggered_id)).invalid()
					    })

				    let new_triggered_id = Common.getNegativeId()
				    let insert = {
				    	triggered_id 					: new_triggered_id,
						triggered_triggerentity 		: parseInt(triggerentity_id),
						triggered_actestype 			: parseInt(params.triggered_actestype) || null,
						triggered_categorie 			: parseInt(params.triggered_categorie) || null,
						triggered_valide 				: 1,
						triggered_triggeredentitytype 	: params.triggeredentity_triggeredentitytype,
						triggered_licencekey  			: ""
					}
					if(!params.global)
				    	insert.triggered_licencekey = Constants.USER_LICENCE_KEY
				    this.$storage.db.t('triggered')
				    .then(table => {
				        return table.add(insert)
				    })

				    params.periods.periods.forEach((period, index) => {
				    	let insert = {
				    		triggeredperiod_id 			: Common.getNegativeId(),
				    		triggeredperiod_triggered 	: parseInt(new_triggered_id),
							triggeredperiod_begin_at 	: params.periods.periods[index],
							triggeredperiod_recurrence 	: params.periods.pas[index],
							triggeredperiod_duration 	: params.periods.duree[index],
							triggeredperiod_billable 	: params.periods.billable[index].id,
							triggeredperiod_actestype	: params.periods.actestype[index] ? params.periods.actestype[index].id : null,
							triggeredperiod_valide 		: 1,
							triggeredperiod_licencekey 	: ""
				    	}

						switch(this.$i18n.locale()) {
							case 'fr':
								insert.triggeredperiod_label = params.periods.label[index]
								insert.triggeredperiod_label_en = params.periods.label_en[index]
								break;
							case 'en':
								insert.triggeredperiod_label = params.periods.label_fr[index]
								insert.triggeredperiod_label_en = params.periods.label[index]
								break;
							default:
								insert.triggeredperiod_label = params.periods.label[index]
						}

				    	if(!params.global)
				    		insert.triggeredperiod_licencekey = Constants.USER_LICENCE_KEY
					    this.$storage.db.t('triggered_period')
					    .then(table => {
					        return table.add(insert)
					    })
				    })

				    // on supprime l'ancien triggered
				    this.$storage.db.t('triggered')
					    .then(table => {
					    	return table.where('triggered_id').equals(parseInt(triggered_id)).invalid()
					    })

					return true
				})
		},
		getTriggered: async function(triggerentity_id) {
			return this.$storage.db.t('triggered')
                .then(table => {
                    return table.where({triggered_triggerentity: parseInt(triggerentity_id)})
                })
                .then(col => {
                    return col.transform(new TriggeredTransformer('light'))
                }).then(triggered => {
                    return triggered[0]
                })
		},
		getPeriodsByTriggered: async function(triggered) {
			return this.$storage.db.t('triggered_period')
                .then(table => {
                    return table.where({triggeredperiod_triggered: parseInt(triggered)})
                })
                .then(col => {
                    return col.transform(new TriggeredTransformer('triggeredPeriod'))
                })
		},
		saveActiveTrigger: async function(triggerentity_id, active) {
			await this.$storage.db.t('trigger_entity_active')
			    .then(table => {
			    	return table.where('triggerentityactive_triggerentity').equals(parseInt(triggerentity_id)).invalid()
			    })

			await this.$storage.db.t('trigger_entity_active')
			    .then(table => {
			        return table.add({
			            triggerentityactive_id: Common.getNegativeId(),
			            triggerentityactive_triggerentity: triggerentity_id,
			            triggerentityactive_active: active
			        })
			    })
		},
		deleteTriggerEntity: async function(triggerentity_id) {
            await this.$storage.db.t('trigger_entity')
			    .then(table => {
			    	return table.where('triggerentity_id').equals(parseInt(triggerentity_id)).invalid()
			    })
        },
	}
}

export default TriggerMixin
