import Transformer from './Transformer'
import ActeTypeTransformer from './ActeTypeTransformer'
import CategorieTransformer from './CategorieTransformer'

export default class TriggeredTransformer extends Transformer {

	fetchPeriods(col) {
        return col.with({
            'periods': 'triggered_period',
        })
    }

    transformPeriods(triggered) {
        return triggered.periods
    }

    async transformTriggeredPeriod(triggered_period) {
        return {
            triggeredperiod_label       : triggered_period.triggeredperiod_label,
            triggeredperiod_label_en    : triggered_period.triggeredperiod_label_en,
            triggeredperiod_begin_at    : triggered_period.triggeredperiod_begin_at,
            triggeredperiod_recurrence  : triggered_period.triggeredperiod_recurrence,
            triggeredperiod_duration    : triggered_period.triggeredperiod_duration,
            triggeredperiod_billable    : triggered_period.triggeredperiod_billable,
            triggeredperiod_actestype    : triggered_period.triggeredperiod_actestype,
        }
    }

    transformTriggeredEntityType(triggeredentitytype) {
        return {
            triggeredentitytype_id        : triggeredentitytype.triggeredentitytype_id,
            triggeredentitytype_label     : triggeredentitytype.triggeredentitytype_label,
            triggeredentitytype_type      : triggeredentitytype.triggeredentitytype_type,
            triggeredentitytype_valide    : triggeredentitytype.triggeredentitytype_valide,
        }
    }    

    fetchTriggeredEntityTypeLabel(col) {
        return col.with({
            'triggered_entity_type' : 'triggered_triggeredentitytype',
        })
    }

    transformTriggeredEntityTypeLabel(triggered) {
        return triggered.triggered_entity_type.triggeredentitytype_label
    }


    fecthTriggeredWithActe(col) {
        return col.with({
            'actes_type'    : 'triggered_actestype',
            'categorie'     : 'triggered_categorie'
        })
    }

    async transformTriggeredWithActe(triggered) {
        return {
            actestype_id        : triggered.triggered_actestype.actestype_id,
            actestype_label     : triggered.triggered_actestype.actestype_label,
            categorie_id        : triggered.triggered_categorie.categorie_id,
            categorie_libelle   : triggered.triggered_categorie.categorie_libelle,
        }
    }

    fetchLight(col) {
        return col.with({
            'triggered_entity_type' : 'triggered_triggeredentitytype',
            'actes_type'            : 'triggered_actestype',
            'categorie'             : 'triggered_categorie'
        })
    }

    async transformLight(triggered) {
        return {
            triggered_id                : triggered.triggered_id,
            triggered_triggerentitytype : await this.transformTriggeredEntityType(triggered.triggered_entity_type),
            triggered_actestype         : await ActeTypeTransformer.process(triggered.actes_type, 'withGroups'),
            triggered_categorie         : await CategorieTransformer.process(triggered.categorie)
        }
    }

	fetchLite(col) {
        return col.with({
            'triggered_entity_type' : 'triggered_triggeredentitytype',
        })
    }

    async transformLite(triggered) {
        return {
            triggered_id                : triggered.triggered_id,
            triggered_triggerentitytype : await this.transformTriggeredEntityType(triggered.triggered_entity_type)
        }
    }

	async transformVeryLight(triggered) {
        return {
            triggered_id                : triggered.triggered_id,
        }
    }
}