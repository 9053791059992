import Transformer from './Transformer'
import TriggeredTransformer from './TriggeredTransformer'
import ActeTypeTransformer from './ActeTypeTransformer'
import QuestionTransformer from './QuestionTransformer'

export default class TriggerTransformer extends Transformer {

    async triggered(item) {
        return this.db().t('triggered')
        .then(table => {
            return table
            .where({'triggered_triggerentity': item.triggerentity_id})
        })
        .then(col => {
            return col.transform(new TriggeredTransformer('light'))
        })
    }

    async transformEntityType(triggerentitytype) {
        return {
            triggerentitytype_id        : triggerentitytype.triggerentitytype_id,
            triggerentitytype_label     : triggerentitytype.triggerentitytype_label,
            triggerentitytype_valide    : triggerentitytype.triggerentitytype_valide,
        }
    }

    fetchTrigger(col) {
        return col.with({
            'question'    : 'trigger_question',
        })
    }

    async transformTrigger(trigger) {
        return {
            trigger_id              : trigger.trigger_id,
            trigger_reponse         : trigger.trigger_reponse,
            question                : await QuestionTransformer.process(trigger.question),
            trigger_valide          : trigger.trigger_valide,
            trigger_triggerentity   : trigger.trigger_triggerentity,
        }
    }

    fetchEntityWithPeriod(col) {
        this.additionalColumns({
            'triggered': this.triggered,
        })

        return col.with({
            'actes_type'    : 'horse_actes_type',
            'active'        : 'trigger_entity_active',
        })
    }

    async transformEntityWithPeriod(triggerentity) {
        const t = await TriggeredTransformer.process(triggerentity.triggered[0], 'triggeredWithActe')
		const active = triggerentity.active.find(trigger => trigger.triggerentityactive_valide == 1)
		const triggered_entity_type = await TriggeredTransformer.process(triggerentity.triggered[0], 'lite')
        
        return {
            triggerentity_id    : triggerentity.triggerentity_id,
            actestype_id        : t ? t.actestype_id : null, 
            actestype_label     : t ? t.actestype_label : null,
            categorie_id        : t ? t.categorie_id : null, 
            categorie_libelle   : t ? t.categorie_libelle : null,
            period              : await TriggeredTransformer.process(triggerentity.triggered[0], 'periods'),
            active              : active ? active.triggerentityactive_active : true,
            entity_type         : triggered_entity_type ? triggered_entity_type.triggered_triggerentitytype.triggeredentitytype_label : null,
            triggeredentitytype_type: triggered_entity_type ? triggered_entity_type.triggered_triggerentitytype.triggeredentitytype_type : null
        }
    }

    fetchHeavy(col) {
        this.additionalColumns({
            'triggered': this.triggered,
        })

        return col.with({
            'entity_type'   : 'triggerentity_triggerentitytype',
            'actes_type'    : 'triggerentity_actestype',
            'active'        : 'trigger_entity_active',
        })
    }

    async transformHeavy (triggerentity) {
        return {
			triggerentity_id			: triggerentity.triggerentity_id,
			triggerentity_actestype		: triggerentity.triggerentity_actestype,
			triggerentity_valide 		: triggerentity.triggerentity_valide,
			actes_type 					: await ActeTypeTransformer.process(triggerentity.actes_type, 'withGroups'), 
            actes_type_triggered        : triggerentity.triggered[0].triggered_actestype,
            categorie_triggered         : triggerentity.triggered[0].triggered_categorie,
			trigger_entity_type 	    : await this.transformEntityType(triggerentity.entity_type),
            triggered_entity_type       : triggerentity.triggered[0].triggered_triggerentitytype,
			global 						: triggerentity.triggerentity_licencekey ? false : true,
			active 						: triggerentity.active.length !== 0 ? triggerentity.active[0].triggerentityactive_active : true,
            period                      : await TriggeredTransformer.process(triggerentity.triggered[0], 'periods'),
        }
    }
}
